<template>
  <div :style="styleVar">
    <!-- 头像 -->
    <v-row no-gutters>
      <v-col cols="4">
        <v-img
          :src="avatar"
          max-width="70px"
          class="rounded-circle"
          aspect-ratio="1"
        />
      </v-col>
    </v-row>
    <!-- ownerid -->
    <v-row no-gutters class="mt-5">
      <v-col cols="8" align-self="center">
        <div class="text-left label-text d-inline-block">
          ID
        </div>
        <div v-if="ownerid" class="content-text d-inline-block">
          {{ ownerid }}
        </div>
        <span v-if="!ownerid" class="d-inline-block ownerid-edit">
          <v-text-field
            v-model="new_ownerid"
            single-line
            :placeholder="$t('bag.create_ownerid')"
            required
            hide-details
            class="py-0 content-text"
            @focus="showConfirm=true"
          />
        </span>
        <v-img
          v-if="!ownerid"
          :src="require('@/assets/icon/edit@3x.png')"
          width="18px"
          height="18px"
          class="d-inline-block ml-2"
          style="position:relative;top:10px;"
        />
        <div class="bottom-line mt-2" :style="{ backgroundColor : showConfirm?'#000000' : '#EEEFF4'}" />
        <v-row v-if="showConfirm" justify="center" no-gutters>
          <v-col cols="12">
            <div class="ownerid-confirm-pannel">
              <v-row justify="center" no-gutters>
                <v-col cols="12" class="mt-6">
                  <div class="confirm-text px-5">
                    {{ $t('bag.ownerid_tips') }}
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center" no-gutters class="mt-5">
                <v-col cols="12">
                  <v-btn class="ownerid-btn-cancel ml-3" @click="cancelSet()">
                    {{ $t('bag.cancel') }}
                  </v-btn>
                  <v-btn class="ownerid-btn-ok ml-2" @click="setOwnerID()">
                    {{ $t('bag.confirm') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- 手机号 -->
    <v-row no-gutters class="mt-5">
      <v-col>
        <div class="text-left label-text d-inline-block">
          {{ $t('bag.phone') }}
        </div>
        <div class="content-text d-inline-block">
          {{ phone }}
        </div>
        <div class="bottom-line mt-2" />
      </v-col>
    </v-row>
    <!-- 邮箱 -->
    <v-row no-gutters class="mt-5">
      <v-col>
        <div class="text-left label-text d-inline-block">
          {{ $t('bag.email') }}
        </div>
        <div class="content-text d-inline-block">
          {{ email }}
        </div>
        <div class="bottom-line mt-2" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { updateUserInfo } from '@/api/user.js'
export default {
  name: 'Profile',
  data() {
    return {
      showConfirm: false,
      new_ownerid: '',
      styleVar: {
        '--label-width': '150px'
      }
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'ownerid',
      'phone',
      'email'
    ])
  },
  watch: {
    '$store.getters.language'(_new) {
      if (_new === 'en') {
        this.styleVar['--label-width'] = '139px'
      } else {
        this.styleVar['--label-width'] = '72px'
      }
    }
  },
  created() {
    if (this.$store.getters.token) {
      if (!this.phone || !this.email || this.ownerid) {
        this.$store.dispatch('GetUserInfo')
      }
    }
    this.styleVar['--label-width'] = this.$store.getters.language === 'en' ? '139px' : '72px'
  },
  methods: {
    setOwnerID() {
      if (!this.new_ownerid) {
        return
      }
      updateUserInfo({ ownerId: this.new_ownerid }).then(resp => {
        this.showConfirm = false
        this.$store.dispatch('GetUserInfo')
        this.$notify({
          text: this.$t('bag.set_ownerid_ok'),
          type: 'success'
        })
      }).catch(err => console.log(err))
    },
    cancelSet() {
      this.new_ownerid = ''
      this.showConfirm = false
    }
  }
}
</script>
<style scoped >

.btn-toggle {
  flex-direction: column;
}
.label-text {
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 22px;
  opacity: 0.3;
  width: var(--label-width) !important;
}

.content-text {
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
}
.ownerid-edit>>>.v-text-field {
  padding: 0 !important;
  margin-top: 0px !important;
  width: 220px;

}
.ownerid-edit >>> input {
  line-height: 24px;
  padding: 0px;
}

.bottom-line {
  width: 220px;
  height: 1px;
  background: #EEEFF4;
  margin-left: var(--label-width);
}

.ownerid-edit >>> .v-input__slot::before{
  border-style: none;
}
.ownerid-edit >>> .v-input__slot::after{
  border-style: none;
}
.ownerid-confirm-pannel {
  margin-left: var(--label-width);
  width: 220px;
  height: 130px;
  background: #F6F6F9;
  border-radius: 0 0 12px 12px;
  border-top: black solid 2px;
}

.confirm-text {
  font-size: 10px;
  font-weight: 400;
  color: #FF4F22;
  line-height: 16px;
}

.ownerid-btn-cancel {
  width: 82px;
  height: 34px !important;
  background: #FFFFFF !important;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 15px;
}

.ownerid-btn-cancel:hover {
  background: #EDEDED !important;
}

.ownerid-btn-ok {
  width: 104px;
  height: 34px !important;
  background: rgba(180, 255, 89, 1) !important;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  line-height: 15px;
}
 .ownerid-btn-ok:hover {
  background: #7CE004 !important;
 }
</style>
