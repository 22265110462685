<template>
  <v-card flat tile :style="styleVar">
    <!-- 标题 -->
    <v-row no-gutters>
      <v-col cols="2" align-self="center" class="nft-title ml-3 mt-2">
        NFT
      </v-col>
      <v-spacer />
      <v-col cols="9" class="text-right mr-3">
        <v-combobox
          v-model="category_1"
          :items="firstCategoryMap"
          dense
          hide-details
          height="36"
          class="category-border d-inlineblock mr-5"
          :label="$t('bag.first_category')"
          full-width
          open-on-clear
          clearable
          item-color="#F6F6F9"
          :menu-props="{'contentClass':'category-menu'}"
        >
          <template #selection="slotProps">
            <span class="category-selected">{{ slotProps.item.text.split('|')[0] }}</span>
          </template>
          <template #item="slotProps">
            <div class="d-flex justify-space-between category-list-item" style="width:100%">
              <div>{{ slotProps.item.text.split('|')[0] }}</div>
              <div>
                {{ slotProps.item.text.split('|')[1] }}
              </div>
            </div>
          </template>
        </v-combobox>
        <!-- 二级分类隐藏 -->
        <v-combobox
          v-show="false"
          v-model="category_2"
          :items="subCategoryMap"
          dense
          hide-details
          height="36"
          class="category-border d-inlineblock"
          :label="$t('bag.second_category')"
          full-width
          open-on-clear
          clearable
          item-color="#F6F6F9"
          :menu-props="{'contentClass':'category-menu'}"
        />
      </v-col>
    </v-row>
    <!-- 显示道具 -->
    <v-row v-if="nftList.length>0 || (category_1!==null)" no-gutters class="mt-1">
      <v-col v-for="(item) in nftList" :key="item.nftid" cols="4">
        <v-card
          flat
          tile
          hover
          :to="opacity_item_text(item.status)?'':'/nft/'+item.nftid"
          class="nft-card mt-4"
          max-width="292px"
        >
          <v-overlay
            :value="opacity_item_text(item.status)"
            absolute
            opacity="0.3"
            style="border-radius: 12px;"
          >
            <div class="transfing-status d-flex align-center justify-center">
              <span class="transfing-status-text ">{{ $t(opacity_item_text(item.status)) }}</span>
            </div>
          </v-overlay>
          <v-card-text class="pt-3 pb-4">
            <v-img
              :src="item.cover"
              width="268px"
              height="268px"
              aspect-ratio="1"
              class="mx-3"
              style="border-radius: 12px;"
            />
            <p class="nft-name mt-3 ml-5">
              {{ en?item.name_en:item.name }}
            </p>
            <v-row no-gutters justify="center" class="mt-3">
              <v-col cols="6" class="ml-5" align-self="center">
                <span class="nft-no">
                  NO.{{ item.serialid }}
                </span>
              </v-col>
              <v-spacer />
              <v-col class="d-flex justify-end mr-5" align-self="center">
                <v-img
                  v-if="item.level"
                  max-width="87px"
                  max-height="19px"
                  :src="require('@/assets/icon/level_' + item.level.toLowerCase() + '@3x.png')"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center" no-gutters>
      <v-col class="mt-16" style="font-size:24px;font-weight:500;">
        {{ $t('bag.no_nft') }}
      </v-col>
    </v-row>
    <!-- 分页 -->
    <v-row v-show="total>0" justify="end" no-gutters style="margin-top: 60px">
      <v-col cols="12" class="d-flex justify-end">
        <v-pagination
          v-model="query.page"
          circle
          :length="Math.ceil(total/query.pagecount)"
          class="text-caption"
          total-visible="7"
        />
      </v-col>
    </v-row>
    <div id="calc-category-widht" ref="calc-category-widht">
      {{ testText }}
    </div>
  </v-card>
</template>
<script>
import { nftList, nftCountByType } from '@/api/drop.js'
export default {
  name: 'NftList',
  data() {
    return {
      firstCategory: ['fashion', 'art', 'collectibles', 'music', 'gaming'],
      secondCategory: {
        'fashion': ['shoes', 'tees', 'hoodies', 'pants', 'caps', 'bags', 'necklaces', 'tattoo'],
        'art': ['image', 'video', '3d', 'd3d'],
        'collectibles': ['image', 'video', '3d', 'd3d'],
        'music': ['music', 'mv'],
        'gaming': ['role', 'pet', 'mount', 'furniture', 'prop']
      },
      category_1: null,
      category_2: null,
      firstCategoryMap: [],
      subCategoryMap: [],
      query: {
        page: 1,
        pagecount: 6
      },
      nftList: [],
      total: 0,
      en: this.$i18n.locale === 'en',
      nftCntMap: {},
      styleVar: {
        '--category-input-padding-left': '16px'
      },
      testText: ''
    }
  },
  watch: {
    '$i18n.locale'(n) {
      // console.log(n)
      this.en = n === 'en'
      this.firstCategoryMap.splice(0)
      this.firstCategory.forEach((element) => {
        this.firstCategoryMap.push({ text: this.$t('category.' + element) + '|' + (this.nftCntMap[element] || 0), value: element })
      })
      if (this.category_1) {
        this.subCategoryMap.splice(0)
        this.subCategoryMap = this.secondCategory[this.category_1.value].map((val) => ({ text: this.$t(this.category_1.value + '.' + val), value: val }))
      }
    },
    category_1(n) {
      this.category_2 = null
      if (!n) {
        this.subCategoryMap.splice(0, this.subCategoryMap.length)
        this.styleVar['--category-input-padding-left'] = '16px'
      } else {
        this.subCategoryMap = this.secondCategory[n.value].map((val) => ({ text: this.$t(n.value + '.' + val), value: val }))
        this.styleVar['--category-input-padding-left'] = '0px'
      }
      this.getNftList()
    },
    'query.page'(n) {
      this.getNftList()
    },
    category_2(n) {
      this.getNftList()
    }
  },
  created() {
    this.getNftList().then(() => {
      this.getNftCountByType().then(() => {
        this.firstCategory.forEach((element) => {
          this.firstCategoryMap.push({ text: this.$t('category.' + element) + '|' + (this.nftCntMap[element] || 0), value: element })
        })
      })
    })
  },

  methods: {
    getNftList() {
      const data = {
        category: this.category_1 ? this.category_1.value : null,
        second_category: this.category_2 ? this.category_2.value : null,
        page: this.query.page,
        pagecount: this.query.pagecount
      }
      return nftList(data).then(resp => {
        this.nftList = resp.data.items
        this.total = resp.data.total
      }).catch(err => console.error(err))
    },
    opacity_item_text(st) {
      if (st === 'transing') {
        return 'drop.transfing'
      } else if (st === 'onsale') {
        return 'drop.onsale'
      } else {
        return null
      }
    },
    getNftCountByType() {
      return nftCountByType().then(resp => {
        this.nftCntMap = resp.data
      }).catch(err => {
        console.log(err)
      })
    }

  }
}
</script>
<style scoped>
.category-border {
  border: 2px solid #18191F !important;
  border-radius: 18px;
  display: inline-block;
  width: 170px;
}
.category-border>>>.v-input__append-inner {
  margin-top: 0px !important;
}
.category-border>>>.v-select__slot {
  overflow: hidden;
  height: 20px;
}
.category-border>>>.v-select__slot label {
  transform: translateY(-50%);
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  line-height: 20px;
  margin-left: 16px;
}

.category-border>>>.v-input__control>.v-input__slot:before{
    border-style: none;
}
.category-border>>>.v-input__control>.v-input__slot:after{
    border-style: none;
}
.category-selected {
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  line-height: 20px;
  padding:0 0 0 16px !important;
}
.category-border>>> input {
  padding: 0 0 0 var(--category-input-padding-left) !important;
  width: 3px !important;
  min-width: 0px !important;
}
.category-border>>>.mdi-menu-down::before{
  content: "";
  display: inline-block;
  background:url('~@/assets/icon/chevron-down@3x.png') no-repeat 0 0;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}
.category-border>>>.mdi-close::before{
  content: "";
  display: inline-block;
  background:url('~@/assets/icon/delete@3x.png') no-repeat 0 0;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
}
.category-border>>>.mdi-close {
  margin-right: 21px;
}
.category-border>>>.mdi-menu-down::after{
  content: none;
}
.category-border>>>.mdi-menu-down {
  margin-right: 16px;
}
.category-border>>>.v-input__append-inner {
  padding-left: 0px;
  width: 16px;
  overflow: visible;
}

.category-border>>>.v-input__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}

/* 定制下拉框 */
.category-menu .v-list{
  padding: 16px 0 16px 0;
  margin-top: 2px;
  background-color: white !important;
}

>>>.v-list-item {
  margin: 5px 10px !important;
  height: 26px !important;
  border-radius: 15px !important;
  min-height: 10px;
  padding-left: 12px;
}

>>>.v-list-item--active:before {
    border-radius: 15px !important;
}
>>> .v-list-item:hover {
  background: #F6F6F9;
}
>>>.v-list-item__content {
  padding: 0px !important;
}
.category-list-item {
  height: 17px;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #18191F;
  line-height: 17px !important;
  white-space: pre;
}
>>>.v-list-item:hover::after {
  opacity: 1;
  position: absolute;
  left: calc(100% - 16px);
  top: calc(100% - 13px);
  content: none !important;
  display: inline-block;
  background:url('~@/assets/icon/Finger@3x.png') no-repeat 0 0;
  background-size: 19px 20px;
  width: 19px;
  height: 20px;
}
.transfing-status {
    width: 183px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 25px;
    text-align: center;
}
.transfing-status-text {
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  color: #FF4F22;
  line-height: 19px;
}
.nft-title {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  line-height: 32px;
  height: 33px;
}
.nft-name {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
}
.nft-no {
  font-size: 16px;
  font-weight: bold;
  color: #1947E5;
  line-height: 22px;
}

>>>.v-pagination__item{
  height: 28px;
  min-width: 28px;
}
>>>.v-pagination__item--active{
  background-color: black !important;
}
>>>.v-pagination__navigation {
  box-shadow: none !important;
}
>>>.v-pagination__navigation .mdi-chevron-left::before {
  content: "";
  display: inline-block;
  background:url('~@/assets/icon/chevron-left-light@3x.png') no-repeat 0 0;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}
>>>.v-pagination__navigation .mdi-chevron-right::before {
  content: "";
  display: inline-block;
  background:url('~@/assets/icon/chevron-right-light@3x.png') no-repeat 0 0;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}
>>>.v-pagination__item {
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
}
>>>.v-pagination__item:not(.v-pagination__item--active) {
  opacity: 0.6;
}
.nft-card {
  border-radius: 12px !important;
}

.nft-card>>>.v-card__subtitle, .v-card__text, .v-card__title {
  padding: 0px;
}
.nft-card p {
  margin-bottom: 0px;
}

.nft-card:hover {
  box-shadow: 0px 6px 18px 0px rgba(194, 194, 194, 0.5) !important;
}
.nft-card:hover::after{
  opacity: 1 !important;
  position: absolute;
  left: calc(100% - 16px);
  top: calc(100% - 10px);
  content: none;
  display: inline-block;
  background:url('~@/assets/icon/Finger@3x.png') no-repeat 0 0;
  background-size: 19px 20px;
  width: 19px;
  height: 20px;
  z-index: 999;
}

#calc-category-widht{
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap; /* Thanks to Herb Caudill comment */
}

</style>

