<template>
  <div style="margin-top:158px;">
    <v-row no-gutters>
      <v-col style="max-width:130px" />
      <v-col>
        <v-row justify="center" no-gutters style="width:1180px;">
          <v-col cols="5" style="max-width:250px">
            <v-card
              tile
              flat
              class="left-menu"
            >
              <!-- 头像 -->
              <v-row no-gutters justify="center">
                <v-col align-self="center" cols="12" class="mt-5">
                  <v-img
                    max-width="40px"
                    max-height="40px"
                    aspect-ratio="1"
                    :src="avatar"
                    class="rounded-circle mx-auto menu-avatar"
                    @click="handleAvatar()"
                  />
                </v-col>
              </v-row>
              <!-- 按钮 -->
              <v-row no-gutters class="mt-5">
                <v-col cols="12" class="mx-auto">
                  <v-btn-toggle
                    v-model="btnSelected"
                    class="flex-column menu-btn-groups"
                    borderless
                    @change="btnChange"
                  >
                    <v-btn
                      class="menu-text"
                    >
                      NFT
                    </v-btn>
                    <v-btn class="menu-text mt-2">
                      {{ $t('bag.wallet') }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- 退出按钮 -->
              <v-row style="margin-top:232px;" no-gutters>
                <v-col class="text-center">
                  <v-btn
                    class="btn-style-three exit-btn"
                    @click="handleLogout()"
                  >
                    {{ $t('bag.logout') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- 容器 -->
          <v-col style="max-width:889px;margin-left:38px">
            <nft-list v-if="btnSelected===0" />
            <wallet-info v-if="btnSelected===1" />
            <profile v-if="btnSelected===10" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import NftList from '../components/NftList.vue'
import Profile from '../components/Profile.vue'
import { mapGetters } from 'vuex'
import WalletInfo from '../components/WalletInfo.vue'
export default {
  name: 'Bag',
  components: { NftList, Profile, WalletInfo },
  data() {
    return {
      btnSelected: 0
    }
  },
  computed: {
    ...mapGetters([
      'avatar'
    ])
  },
  watch: {
    '$route'(n) {
      if (n.path === '/nft') {
        this.btnSelected = 0
      } else if (n.path === '/wallet') {
        this.btnSelected = 1
      } else {
        this.btnSelected = 10
      }
    }
  },
  created() {
    const path = this.$route.path
    if (path === '/nft') {
      this.btnSelected = 0
    } else if (path === '/wallet') {
      this.btnSelected = 1
    } else {
      this.btnSelected = 10
    }
  },
  methods: {
    handleAvatar() {
      if (this.$store.getters.token) {
        this.$router.push({ path: '/profile' })
      } else {
        this.$store.dispatch('showLoginDlg', '/profile')
      }
    },
    handleLogout() {
      if (this.$store.getters.token) {
        this.$store.dispatch('FedLogOut')
        this.$notify({
          'text': this.$t('login.logout_ok'),
          'type': 'success'
        })
        this.$router.replace('/home')
      }
    },
    btnChange(val) {
      if (val === 0) {
        this.$router.push({ path: '/nft' }).catch(err => err)
      } else if (val === 1) {
        this.$router.push({ path: '/wallet' })
      }
    }
  }
}
</script>
<style scoped>

.left-menu {
  width: 250px;
  height: 490px;
  background:rgba(180, 255, 89, 0.1);
  border-radius: 12px;
}

.menu-avatar:hover {
  cursor: pointer;
}

.menu-text {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  line-height: 32px;
  text-transform: none;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  background-color: transparent !important;
}

.v-btn-toggle {
  background-color: inherit !important;
  display: flex;
  justify-items: center;
  align-items: center;
}

.v-btn-toggle>.v-item--active {
  background-color: rgba(180, 255, 89, 1) !important;
}

.v-btn-toggle > .v-btn {
  border-radius: 25px 25px 25px 25px !important;
}
.v-btn-toggle > .v-btn:not(.v-btn--active):hover {
  background: rgba(180, 255, 89, 0.2) !important;
}

.menu-btn-groups>>>.v-btn:not(.v-btn--active):hover::before {
  opacity: 1;
  position: absolute;
  left: calc(100% - 16px);
  top: calc(100% - 13px);
  content: none !important;
  display: inline-block;
  background:url('~@/assets/icon/Finger@3x.png') no-repeat 0 0;
  background-size: 19px 20px;
  width: 19px;
  height: 20px;
}
.exit-btn {
  width: 200px;
  height: 48px !important;
}
.exit-btn >>> span {
  height: 33px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  text-transform: capitalize;
}

</style>
