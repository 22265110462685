<template>
  <v-card tile flat max-width="580px">
    <v-card-title class="pa-0 ml-3">
      <span
        :class="[{ 'wallet-title-light': coinSelect }, 'wallet-title']"
        @click="coinSelect = null"
        >{{ $t("bag.wallet") }}</span
      >
      <span v-if="coinSelect" class="wallet-title"
        >&nbsp;&lt;&nbsp;{{ coinSelect ? coinSelect.assetType : "" }}</span
      >
    </v-card-title>
    <!-- 资产列表 -->
    <v-card-text v-show="!coinSelect" class="mt-5 pa-0">
      <v-row
        v-for="item in assets"
        :key="item.id"
        no-gutters
        style="margin-top: 6px"
      >
        <v-hover v-slot="{ hover }">
          <v-card width="100%" flat tile>
            <v-row no-gutters>
              <v-col
                cols="12"
                :class="[{ 'asset-item-hover': hover }, 'asset-item']"
                align-self="center"
                @click="handleSelectCoin(item.id)"
              >
                <v-row no-gutters justify="center" class="fill-height">
                  <v-col align-self="center" style="max-width: 30px">
                    <v-img
                      :src="item.logo"
                      class="rounded-circle"
                      max-width="30px"
                      max-height="30px"
                      width="100%"
                      aspect-ratio="1"
                    />
                  </v-col>
                  <v-col align-self="center" class="currency-text ml-3">
                    {{ item.assetType }}
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="5"
                    align-self="center"
                    class="text-right balance-text pr-7"
                  >
                    {{
                      formatCurrenyBalance(
                        item.assetType,
                        item.balanceAvailableStr
                      )
                    }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-row>
    </v-card-text>
    <!-- 充值和交易记录 -->
    <v-card-text v-if="coinSelect" style="margin-top: 30px">
      <v-tabs
        v-model="tabSelect"
        style="border-bottom: 1px solid #f4f5f7"
        class="deposit-tab"
      >
        <v-tab class="tab-text">
          {{ $t("bag.trans_records") }}
        </v-tab>
        <v-tab class="tab-text">
          {{ $t("bag.deposit") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabSelect">
        <!-- 交易记录 -->
        <v-tab-item>
          <v-card v-if="tradeRecords.length > 0" flat tile class="mt-7">
            <v-row
              v-for="item in tradeRecords"
              :key="item.id"
              no-gutters
              class="mb-7"
            >
              <v-col cols="2" align-self="center" style="max-width: 78px">
                <div class="trade-img-box d-flex justify-center align-center">
                  <v-img :src="item.cover" max-width="60px" aspect-ratio="1" />
                </div>
              </v-col>
              <v-col cols="6" align-self="center" class="ml-3">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    align-self="center"
                    class="deposit-list-text-1"
                    style="opacity: 1"
                  >
                    {{ item.description }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-4">
                  <v-col
                    cols="8"
                    class="deposit-list-text-3"
                    align-self="center"
                  >
                    {{ item.createTime }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="4"
                :class="[
                  {
                    'deposit-list-text-get': item.amount >= 0,
                    'deposit-list-text-cost': item.amount < 0,
                  },
                  'deposit-list-text-2 text-right',
                ]"
                align-self="center"
              >
                {{
                  formatCurrenyBalance(item.assetType, item.amount) +
                  " " +
                  item.assetType
                }}
              </v-col>
              <v-spacer />
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-end mt-11">
                <v-pagination
                  v-if="tradeQuery.total > 0"
                  v-model="tradeQuery.page"
                  circle
                  total-visible="7"
                  :length="Math.ceil(tradeQuery.total / tradeQuery.rows)"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-row
            v-else
            no-gutters
            class="mt-7"
            style="font-size: 24px; font-weight: 500; color: black"
          >
            <v-col>
              {{ $t("bag.no_trade") }}
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- 充值 -->
        <v-tab-item>
          <!-- 充值首页 -->
          <v-card v-if="!showDeposit">
            <v-row no-gutters>
              <v-col cols="12" align-self="center" class="text-right mt-5">
                <v-btn
                  small
                  text
                  class="record-btn-text text-capitalize"
                  @click="showDeposit = true"
                >
                  {{ $t("bag.deposit_record") }}>
                </v-btn>
              </v-col>
            </v-row>
            <!-- 网络 -->
            <v-row no-gutters class="mt-2">
              <v-col cols="4" align-self="center" class="label-text">
                {{ $t("bag.blockchain") }}:
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              <v-col cols="3" align-self="center">
                <div
                  class="
                    network-box network-text
                    d-flex
                    justify-center
                    align-center
                  "
                >
                  {{ coinSelect.blockchain }}
                </div>
              </v-col>
            </v-row>
            <!-- 钱包地址 -->
            <v-row v-if="coinSelect.address" no-gutters class="mt-7">
              <v-col cols="12" align-self="center" class="label-text">
                {{ $t("bag.address") }}:
              </v-col>
            </v-row>
            <v-row v-if="coinSelect.address" no-gutters class="mt-3">
              <v-col cols="10" style="max-width: 460px" align-self="center">
                <div
                  class="
                    address-box address-text
                    d-flex
                    justify-center
                    align-center
                  "
                >
                  {{ coinSelect.address }}
                </div>
              </v-col>
              <v-col align-self="center" class="ml-5">
                <v-btn
                  text
                  class="copy-text"
                  @click="copyAddress(coinSelect.address, $event)"
                >
                  {{ $t("bag.copy") }}
                </v-btn>
              </v-col>
            </v-row>
            <!-- 钱包地址二维码 -->
            <div v-if="coinSelect.address">
              <v-row no-gutters class="mt-7 fill-height">
                <v-col cols="4" align-self="center" class="label-text">
                  {{ $t("bag.address_qr") }}:
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4">
                <v-col cols="6" style="max-width: 232px">
                  <qrcode-vue
                    id="qr"
                    size="232"
                    :value="coinSelect.address"
                    level="H"
                    render-as="svg"
                  />
                </v-col>
                <v-col class="d-flex justify-start align-center ml-7">
                  <v-btn text class="copy-text" @click="downloadQr()">
                    {{ $t("bag.download_qr") }}
                  </v-btn>
                </v-col>
              </v-row>
              <!-- 说明 -->
              <v-row no-gutters class="mt-4">
                <v-col class="warn-text">
                  <p class="ma-0">
                    {{
                      $t("bag.wallet_warn_1", { NET: coinSelect.blockchain })
                    }}
                  </p>
                  <p class="ma-0 mb-15">
                    {{ $t("bag.wallet_warn_2") }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <!-- 充值记录 -->
          <v-card v-if="showDeposit">
            <v-row no-gutters class="mb-7">
              <v-col cols="12" align-self="center" class="text-left mt-5">
                <v-btn
                  small
                  text
                  class="record-btn-text"
                  @click="showDeposit = false"
                >
                  <v-img
                    :src="require('@/assets/icon/chevron-left@3x.png')"
                    width="24px"
                    height="24px"
                  />{{ $t("bag.deposit_record") }}
                </v-btn>
              </v-col>
            </v-row>
            <!-- 充值列表 -->
            <div v-if="depositRecords.length > 0">
              <v-row
                v-for="item in depositRecords"
                :key="item.id"
                no-gutters
                class="mb-7"
              >
                <v-col cols="2" align-self="center" style="max-width: 54px">
                  <div
                    class="wallet-img-box d-flex justify-center align-center"
                  >
                    <v-img
                      :src="require('@/assets/icon/wallet@3x.png')"
                      max-width="30px"
                      max-height="30px"
                    />
                  </div>
                </v-col>
                <v-col cols="5" align-self="center" class="ml-3">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      align-self="center"
                      class="deposit-list-text-1"
                    >
                      {{ $t(item.typeDescription) }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-4">
                    <v-col
                      cols="12"
                      class="deposit-list-text-3"
                      align-self="center"
                    >
                      {{ item.createTime }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer />
                <v-col
                  cols="4"
                  class="deposit-list-text-2 text-right deposit-list-text-get"
                  align-self="center"
                >
                  {{
                    formatCurrenyBalance(item.assetType, item.amount) +
                    " " +
                    item.assetType
                  }}
                </v-col>
              </v-row>
              <div v-if="depositRecords.length === 0" style="opacity: 0">
                empty
              </div>
              <v-row no-gutters>
                <v-col class="d-flex justify-end mt-11">
                  <v-pagination
                    v-if="depositQuery.total > 0"
                    v-model="depositQuery.page"
                    circle
                    total-visible="7"
                    :length="Math.ceil(depositQuery.total / depositQuery.rows)"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row
              v-else
              no-gutters
              class="mt-7 ml-3"
              style="font-size: 24px; font-weight: 500; color: black"
            >
              <v-col>
                {{ $t("bag.no_deposit") }}
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { getWalletInfo, getWalletTrans } from "@/api/user.js";
import QrcodeVue from "qrcode.vue";
import clip from "@/utils/clipboard.js";
import saveSvgAsPng from "save-svg-as-png";
import { formatDecimal } from "@/utils";
export default {
  name: "WalletInfo",
  components: { QrcodeVue },
  data() {
    return {
      assets: [],
      coinSelect: null, // 选择的货币
      tabSelect: 0,
      tradeRecords: [],
      tradeQuery: {
        coinType: "", // 人民币：RMB ,不要传CNY
        queryType: "trade", // trade/deposit
        page: 1,
        rows: 5,
        total: 0,
      },
      depositRecords: [],
      depositQuery: {
        coinType: "", // 人民币：RMB ,不要传CNY
        queryType: "deposit", // trade/deposit
        page: 1,
        rows: 5,
        total: 0,
      },
      showDeposit: false,
    };
  },
  watch: {
    "tradeQuery.page"(n) {
      this.getTransRecords("trade");
    },
    "depositQuery.page"(n) {
      this.getTransRecords("deposit");
    },
    tabSelect(n) {
      this.showDeposit = false;
      if (n === 0) {
        this.resetQuery();
        this.getTransRecords("trade");
      }
    },
    showDeposit(n) {
      if (n === true) {
        this.resetQuery();
        this.getTransRecords("deposit");
      }
    },
  },
  mounted() {
    this.getWallet();
  },
  methods: {
    resetQuery() {
      this.tradeQuery.coinType = "";
      this.tradeQuery.page = 1;
      this.tradeQuery.total = 0;
      this.depositQuery.coinType = "";
      this.depositQuery.page = 1;
      this.depositQuery.total = 0;
      this.tradeRecords.splice(0);
      this.depositRecords.splice(0);
    },
    getWallet() {
      getWalletInfo()
        .then((resp) => {
          this.assets = [];
          resp.data.forEach((element) => {
            if (
              element.assetType === "SOL" ||
              element.assetType === "USDC" ||
              element.assetType == "REAL"
            ) {
              this.assets.splice(0, 0, element);
            }

            console.log(this.assets)
          });
          // this.assets = resp.data
        })
        .catch((err) => console.error(err));
    },
    handleSelectCoin(coinid) {
      for (let i = 0; i < this.assets.length; i++) {
        const item = this.assets[i];
        if (item.id === coinid) {
          this.coinSelect = item;
        }
      }
      this.resetQuery();
      this.showDeposit = false;
      if (this.$route.query.t === "assets") {
        this.tabSelect = 1;
      } else {
        this.tabSelect = 0;
        this.getTransRecords("trade");
      }
    },
    getTransRecords(tp) {
      if (tp === "trade") {
        this.tradeQuery.coinType = this.coinSelect.assetType;
        getWalletTrans(this.tradeQuery).then((resp) => {
          this.tradeRecords = resp.data.trade.rows;
          this.tradeQuery.total = resp.data.trade.total;
        });
      } else {
        this.depositQuery.coinType = this.coinSelect.assetType;
        getWalletTrans(this.depositQuery).then((resp) => {
          this.depositRecords = resp.data.deposit.rows;
          this.depositQuery.total = resp.data.deposit.total;
          // for (let i = 0; i < 50; i++) {
          //   this.depositRecords.splice(0, 0, this.depositRecords[0])
          // }
          // this.depositQuery.total = 100// resp.data.deposit.total
        });
      }
    },
    copyAddress(text, event) {
      clip(text, event);
    },
    downloadQr() {
      saveSvgAsPng.saveSvgAsPng(
        document.getElementById("qr").getElementsByTagName("svg")[0],
        this.coinSelect.assetType + "_address.png",
        { scale: 10 }
      );
    },
    formatCurrenyBalance(currency, value) {
      if (currency === "USDC") {
        return parseFloat(formatDecimal(value, 2));
      } else if (currency === "SOL") {
        return parseFloat(parseFloat(value).toFixed(6));
      } else {
        return parseFloat(parseFloat(value).toFixed(6));
      }
    },
  },
};
</script>

<style scoped>
.asset-item {
  max-width: 404px;
  height: 44px;
  background: white;
  border-radius: 25px;
  padding-left: 16px !important;
}
.asset-item-hover {
  background: #f6f6f9;
  cursor: pointer;
}
.wallet-title {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  line-height: 32px;
}
.wallet-title-light {
  opacity: 0.4;
}
.currency-text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
  opacity: 0.6;
}
.balance-text {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  line-height: 22px;
}
.on-hover {
  background-color: rgba(#000000, 0.8);
}
.tab-text {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  line-height: 22px;
  text-transform: capitalize;
}
.label-text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 19px;
  opacity: 0.4;
  text-transform: capitalize;
}
.record-btn-text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
  text-transform: capitalize;
}
.network-box {
  width: 99px;
  height: 36px;
  background: rgba(180, 255, 89, 1);
  border-radius: 6px;
}
.network-text {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
}
.address-box {
  max-width: 460px;
  height: 36px;
  background: #f4f5f7;
  border-radius: 6px;
}
.address-text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
}
.copy-text {
  font-size: 14px;
  font-weight: 600;
  color: #072bff;
  line-height: 18px;
  text-transform: capitalize;
}
.warn-text {
  font-size: 10px;
  font-weight: 400;
  color: #ff4f22;
  line-height: 14px;
}
.wallet-img-box {
  width: 54px;
  height: 54px;
  background: #f6f7fa;
  border-radius: 7px;
}
.deposit-tab {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #000000;
}

.deposit-tab >>> .v-tabs-slider-wrapper {
  height: 4px !important;
}
.deposit-tab >>> .v-tab:not(.v-tab--active) {
  color: rgba(0, 0, 0, 0.4) !important;
}
.deposit-tab >>> .v-tabs-slider {
  width: 30px;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  background: #b4ff59;
  border-radius: 3px;
}
.deposit-list-text-1 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
}
.deposit-list-text-2 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 19px;
}
.deposit-list-text-cost {
  color: #fa1750;
}
.deposit-list-text-get {
  color: #072bff;
}
.deposit-list-text-3 {
  font-size: 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  line-height: 13px;
}
.deposit-list-text-4 {
  font-size: 14px;

  font-weight: bold;
  color: #00c6ae;
  line-height: 20px;
}
.trade-img-box {
  width: 78px;
  height: 78px;
  background: #f6f7fa;
  border-radius: 7px;
}
>>> .v-pagination__item {
  height: 28px;
  min-width: 28px;
}
>>> .v-pagination__item--active {
  background-color: black !important;
}
>>> .v-pagination__navigation {
  box-shadow: none !important;
}
>>> .v-pagination__navigation .mdi-chevron-left::before {
  content: "";
  display: inline-block;
  background: url("~@/assets/icon/chevron-left-light@3x.png") no-repeat 0 0;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}
>>> .v-pagination__navigation .mdi-chevron-right::before {
  content: "";
  display: inline-block;
  background: url("~@/assets/icon/chevron-right-light@3x.png") no-repeat 0 0;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}
>>> .v-pagination__item {
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
  box-shadow: none !important;
}
>>> .v-pagination__item:not(.v-pagination__item--active) {
  opacity: 0.6;
}
</style>
